<template>
  <div class="modal-dialog modal-dialog-centered modal-xl"
       role="document">
    <div class="modal-content">
      <div class="modal-header">
        <p class="modal-title">
          <i class="far fa-file-alt mr-2"></i>
          <span class="font-weight-bold"
                v-text="contract.text(`modal`)"></span>
        </p>
        <button type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body p-2">
        <template v-if="contract.ready">
          <scroll-area class="px-3 pb-3">
            <div class="form-row pt-3">
              <div class="col-12">
                <div class="form-row">
                  <div class="col-3">
                    <label>Date</label>
                    <date-picker class="datepicker"
                                 inputClass="form-control form-control-sm"
                                 v-model="contract.jsDate"
                                 format="MMM D, YYYY"
                                 :clearable="false"
                                 disabled></date-picker>
                    <small class="form-text text-muted">Contract Date</small>
                  </div>

                  <div class="col-5">
                    <label>Reference</label>
                    <input class="form-control form-control-sm"
                           :value="contract.ref"
                           readonly />
                    <small class="form-text text-muted">Contract Reference</small>
                  </div>

                  <div class="col-3"
                       v-if="contract.amendedOn">
                    <label>Amend date</label>
                    <date-picker :clearable="false"
                                 class="datepicker"
                                 inputClass="form-control form-control-sm"
                                 disabled
                                 v-model="contract.amendedOn"
                                 format="MMM D, YYYY"></date-picker>
                    <small class="form-text text-muted">Contract amended on</small>
                  </div>
                </div>
              </div>
              <div class="col-6 mt-4">
                <label>Billing</label>
                <div class="form-row">
                  <div class="col">
                    <input class="form-control form-control-sm text-capitalize"
                           :value="contract.billing.type"
                           readonly />
                    <small class="form-text text-muted">Billing Type</small>
                  </div>
                  <div class="col">
                    <input class="form-control form-control-sm"
                           :value="contract.billing.schedule"
                           readonly />
                    <small class="form-text text-muted">Billing Schedule</small>
                  </div>
                  <div class="col">
                    <input class="form-control form-control-sm"
                           :value="contract.billing.values"
                           readonly />
                    <small class="form-text text-muted">Billing Values</small>
                  </div>
                  <!-- <div class="col">
                    <input
                      class="form-control form-control-sm"
                      :value="contract.expiredOn"
                      readonly
                    />
                    <small class="form-text text-muted">Billing Values</small>
                  </div> -->
                </div>
              </div>
            </div>
            <hr />
            <div v-for="(s, index) in contract.schedules"
                 :key="index"
                 class="row">
              <div class="col mt-2">
                <div class="d-flex">
                  <div>
                    <u class="pl-2"
                       v-text="contract.text(`schedule`, index)"></u>
                  </div>
                  <div class="flex-grow-1 text-right">
                    <template v-if="!index && contract.show">
                      <span class="mr-2">Obfuscate account numbers</span>
                      <checkbox :o="contract.show"
                                p="numbers"
                                title="Toggle obfuscate account numbers"></checkbox>
                    </template>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-4">
                    <div class="row">
                      <div class="col">
                        <span class="title pl-2"
                              v-text="contract.text(`schedule`, s)"></span>
                      </div>
                    </div>
                    <div class="row py-3">
                      <div class="col">
                        <table class="table table-sm schedule mb-1">
                          <thead>
                            <tr>
                              <th>
                                <span>Range</span>
                              </th>
                              <th>
                                <span>Rate</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(t, i) in contract.tiers(s)"
                                :key="i">
                              <td>
                                <span v-text="t.range"></span>
                              </td>
                              <td>
                                <span v-text="t.rate"></span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <template v-if="contract.text(`minimum`, s)">
                          <hr class="my-1" />
                          <span class="font-italic ml-1"
                                v-text="contract.text(`minimum`, s)"></span>
                        </template>
                      </div>
                    </div>
                  </div>
                  <div class="col">
                    <div class="row">
                      <div class="col">
                        <span class="title pl-2">Accounts</span>
                      </div>
                    </div>
                    <div class="row py-3">
                      <div class="col">
                        <table class="table table-sm accounts">
                          <thead>
                            <tr>
                              <th>
                                <span>Number</span>
                              </th>
                              <th>
                                <span>Name</span>
                              </th>
                              <th>
                                <span>Billing</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(a, j) in contract.accounts(s)"
                                :key="j">
                              <td>
                                <span v-text="contract.text(`number`, a)"></span>
                              </td>
                              <td>
                                <span v-text="a.name"></span>
                              </td>
                              <td>
                                <span v-text="a.billing"></span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="contract.exceptions.length">
              <u class="pl-2">Exceptions</u>
              <div class="mt-3">
                <table class="table table-sm exceptions">
                  <thead>
                    <tr>
                      <th>
                        <span>Type</span>
                      </th>
                      <th>
                        <span>Account</span>
                      </th>
                      <th>
                        <span>Schedule</span>
                      </th>
                      <th>
                        <span>Ticker</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(o, i) in contract.exceptions"
                        :key="i">
                      <td>
                        <span class="text-capitalize"
                              v-text="o.type"></span>
                      </td>
                      <td>
                        <span v-text="contract.text(`account`, o)"></span>
                      </td>
                      <td>
                        <span v-text="contract.text(`schedule`, o)"></span>
                      </td>
                      <td>
                        <span v-text="contract.text(`ticker`, o)"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-if="contract._accounts">
              <contract-transactions-creation-table v-if="contract.transactionsRules.length"
                                                    :contractAccounts="contract._accounts"
                                                    v-model="contract.transactionsRules"
                                                    readonly />
            </div>
            <div v-else></div>

            <div v-if="contract.lineItems.length">
              <u class="pl-2">Line Items</u>
              <div class="mt-3">
                <table class="table table-sm">
                  <thead>
                    <tr>
                      <th>
                        <span>Description</span>
                      </th>
                      <th>
                        <span>Account</span>
                      </th>
                      <th>
                        <span>Timing</span>
                      </th>
                      <th>
                        <span>Amount</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(o, i) in contract.lineItems"
                        :key="i">
                      <td>
                        <span v-text="o.description"></span>
                      </td>
                      <td>
                        <span v-text="contract.text(`account`, o)"></span>
                      </td>
                      <td>
                        <span v-text="o.timing"></span>
                      </td>
                      <td>
                        <span v-text="contract.text(`amount`, o.amount)"></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row">
              <div class="col mt-2">
                <u class="pl-2">Signees</u>
              </div>
            </div>
            <template v-if="contract.signees.length">
              <div class="row py-3">
                <div class="col">
                  <table class="table table-sm signees">
                    <thead>
                      <tr>
                        <th>
                          <span>Order</span>
                        </th>
                        <th>
                          <span>Name</span>
                        </th>
                        <th>
                          <span>Email</span>
                        </th>
                        <th>
                          <span>Role</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(s, i) in contract.signees"
                          :key="i">
                        <td>
                          <span v-text="contract.text(`signees`, i)"></span>
                        </td>
                        <td>
                          <span v-text="s.name"></span>
                        </td>
                        <td>
                          <span v-text="s.email"></span>
                        </td>
                        <td>
                          <span class="text-capitalize"
                                v-text="s.role"></span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </template>
            <template v-else>
              <p class="font-weight-bold font-italic text-center no-signees m-3"
                 v-text="contract.text(`signees`)"></p>
            </template>
            <hr />
          </scroll-area>
          <template v-if="contract.expired">
            <span class="expired">Expired</span>
          </template>
        </template>
        <template v-else>
          <loading></loading>
        </template>
      </div>
      <div class="modal-footer pr-3">
        <div v-if="contract.hasPendingInvoices"
             class="alert alert-warning">
          This contract has pending invoices.
        </div>
        <div class="text-right">
          <remove-button :button="contract.remove"></remove-button>
          <template v-if="contract.text(`action`) == `Expire`">
            <button type="button"
                    class="btn btn-sm btn-warning ml-2"
                    :disabled="
                      contract.hasPendingInvoices || contract.disabled(`action`)
                    "
                    v-on:click="contract.amend()">
              <span>Amend</span>
            </button>
          </template>
          <template v-if="contract.text(`action`) == `Expire`">
            <button type="button"
                    class="btn btn-sm btn-warning ml-2"
                    :disabled="
                      contract.hasPendingInvoices || contract.disabled(`action`)
                    "
                    v-on:click="contract.terminate()">
              <span>Terminate</span>
            </button>
          </template>
          <template v-if="contract.text(`action`)">
            <button type="button"
                    class="btn btn-sm btn-primary ml-2"
                    :disabled="
                      contract.hasPendingInvoices || contract.disabled(`action`)
                    "
                    v-on:click="contract.click(`action`)">
              <span v-text="contract.text(`action`)"></span>
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.datepicker {
  display: block;
  width: 100%;
}

div.ref input {
  width: 20em;
}

/*
    div.main {
        height: 70vh;
        margin-right: -8px;
    }
    */
div.modal-body span {
  font-size: 0.9em;
}

div.date {
  width: 14em;
}

div.date span {
  font-size: 0.7rem;
}

p.modal-title i,
p.modal-title span {
  opacity: 0.7;
}

div.modal-body {
  height: calc(100vh - 12em);
}

button {
  width: 80px;
}

p.no-signees {
  opacity: 0.3;
}

u {
  font-weight: 600;
}

span.title {
  font-size: 1em !important;
}

span.expired {
  display: block;
  font-size: 10em !important;
  color: red;
  opacity: 0.3;
  margin-top: -1.5em;
  margin-left: 1.5em;
  transform-origin: 0 0;
  transform: rotate(-30deg);
}
</style>

<script>
import {
  checkbox,
  loading,
  removeButton,
  scrollArea,
} from "../../../../component";
import { Api, Modal, Record } from "../../../../factory";
import { moment, numeral, ordinal } from "../../../../npm";
import { alert, events } from "../../../../service";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import ContractTransactionsCreationTable from "../../../../component/transactions/contract-transactions-creation-table.vue";
import { startCase } from 'lodash';

export default {
  get components() {
    return {
      checkbox,
      DatePicker,
      loading,
      removeButton,
      scrollArea,
      ContractTransactionsCreationTable,
    };
  },
  props: ["data"],
  data() {
    return {
      contract: ``,
    };
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      var { data } = this;
      var contract = {
        _accounts: ``,
        _action() {
          var action = [`Sign`, `Deploy`, `Expire`];
          return action[data.contract.status];
        },
        async _deploy() {
          var { contract: c, methods } = data;
          try {
            var r = await Api.put(`contracts/${c._id}?_action=deploy`);
            if (methods && typeof methods.success == `function`)
              return methods.success(r.data);
            alert.message(r.data);
            events.$emit(`modal`, { action: `hide` });
          } catch (e) {
            console.error(e);
            if (methods && typeof methods.error == `function`)
              return methods.error(e);
            alert.error(e.message);
          }
        },
        async _expire() {
          try {
            var { methods } = data;
            var r = await Api.put(
              `contracts/${data.contract._id}?_action=expire`
            );
            if (methods && typeof methods.success == `function`)
              return methods.success(r.data);
            Modal.close();
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        _households: ``,
        jsDate: ``,
        amendedOn: ``,
        hasPendingInvoices: ``,
        async _load() {
          var { contract, methods } = data;
          try {
            this._pending = true;
            var { data: accounts } = await Api.get(
              `accounts?householdId=${contract.householdId}`
            );
            if (!accounts.length) { 
              Modal.close()
              return alert.error("No accounts associated with the household") 
            }
            this._accounts = accounts;
            this.jsDate = moment(contract.date, "MMM D, YYYY").toDate();

            if (contract.amendedOn)
              this.amendedOn = contract.amendedOn.includes(",")
                ? moment(contract.amendedOn, "MMM D, YYYY").toDate()
                : new Date(contract.amendedOn);

            var { data: accounts } = await Api.get(
              `accounts?householdId=${contract.householdId}`
            );

            const { data: hasPendingInvoices } = await Api.get(
              `contracts/has-pending-invoices?_id=${contract._id}`
            );

            this.hasPendingInvoices = hasPendingInvoices;

            if (hasPendingInvoices) {
              this.remove.disabled = true;
            }

            this.ready = true;
          } catch (e) {
            console.error(e);
            if (methods && typeof methods.error == `function`)
              return methods.error(e);
            alert.error(e.message);
          } finally {
            this._pending = false;
          }
        },
        _pending: ``,
        async _remove() {
          try {
            this.remove.disabled = true;
            var { contract: c, methods } = data;
            var r = await Api.delete(`contracts/${c._id}`);
            if (methods && typeof methods.success == `function`)
              return data.methods.success(r.data);
            Modal.close();
          } catch (e) {
            console.error(e);
            if (methods && methods.error == `function`) return methods.error(e);
            alert.error(e.message);
          } finally {
            this.remove.disabled = false;
          }
        },
        accounts(s) {
          var accounts = data.contract.accounts
            .filter(
              (a) =>
                a.scheduleId ==
                data.contract.schedules.find((o) => o._id == s._id)?._id
            )
            .map((a) => {
              var account = this._accounts.find((o) => o.number == a.number);
              // account is not the list of accounts, probably reassigned to a different household
              if (!account) return null
              var { name, number } = account;
              var billing; 
                if (a.billing == -1) billing = `Self`
                else if (a.billing == 99) billing = `Bill to Invoice`    
                else billing = data.contract.accounts.find(ac => ac._id == a.billing)?.number 
              return { billing, name, number };
            }).filter(a => a); // remove the null accounts
          var s = data.contract.schedules.find((o) => o.name == s.name);
          const hasBillToInvoice = data.contract.accounts.some(ac => ac.billing == 99)
          var exceptions = data.contract.exceptions
            .filter((o) => o.scheduleId == s._id)
            .map((o) => {
              var number = o.number == `*` ? this.ALL : o.number;
              var name = o.ticker.symbol
                ? o.name + ( o.type == 'position' ? ` (Ticker: ${o.ticker.symbol})` : ` (${startCase(o.type)})` )
                : `Cash`;
              var a =
                number == this.ALL
                  ? ``
                  : data.contract.accounts.find((a) => a.number == o.number);
              var billing = a
                ? a.billing == -1
                  ? `Self`
                  : a.billing == 99
                    ? `Bill to Invoice`
                    : data.contract.accounts.find((ac) => ac._id == a.billing).number
                : hasBillToInvoice ? `Bill to Invoice` : `Self`;
              return { billing, name, number };
            });
          return [...accounts, ...exceptions];
        },
        action() {
          var action = this._action().toLowerCase();
          switch (action) {
            case `deploy`:
              return this._deploy();
            case `export`:
              return this._export();
            default:
              alert.error(`${this._action()} is not yet implemented`);
          }
        },
        click(type) {
          switch (type) {
            case `action`:
              var action = this._action().toLowerCase();
              switch (action) {
                case `expire`:
                  return this._expire();
              }
              return alert.warning(`${this._action()} is not yet implemented!`);
            case `remove`:
              return this._remove();
            default:
              throw new Error(`Invalid click type, ${type}!`);
          }
        },
        get created() {
          return moment(data.contract._created).format(`lll`);
        },
        disabled(type) {
          switch (type) {
            case `action`:
              return this._pending || this.hasPendingInvoices;
            default:
              throw new Error(`Invalid disabled type, ${type}!`);
          }
        },
        get date() {
          return data.contract.date;
        },
        get billing() {
          return (
            data.contract.billing || { type: `Unknown`, values: `Unknown` }
          );
        },
        get exceptions() {
          return data.contract.exceptions;
        },
        get lineItems() {
          return data.contract.lineItems;
        },
        get transactionsRules() {
          return data.contract.transactionsRules;
        },
        get expired() {
          return data.contract.status == 3 && this.ready;
        },
        init() {
          this._pending = false;
          this.ready = false;
          this.NONE = `-`;
          this.ALL = 'All Accounts';
          this.remove = {
            click: () => {
              this._remove();
            },
            disabled: ``,
          };
          this._load();
          return this;
        },
        NONE: ``,
        ALL: ``,
        ready: ``,
        get ref() {
          return data.contract.ref;
        },
        remove: ``,
        get schedules() {
          return data.contract.schedules;
        },
        get show() {
          return data.contract.show;
        },
        get signees() {
          return data.contract.signees.map((s) => {
            var { email, group, role } = s;
            var name = `${s.name} (${group})`;
            return { email, name, role };
          });
        },
        get template() {
          return data.contract.template || `No Template`;
        },
        text(type, d) {
          var number = (n) =>
            this.show && this.show.numbers
              ? [
                ...new Array(Math.floor(n.length / 2)).fill(`*`),
                n.slice(Math.floor(n.length / 2)),
              ].join(``)
              : n;
          switch (type) {
            case `amount`:
              return numeral(d).format(`$0,0.00`);
            case `action`:
              return this._action();
            case `account`:
              if (!d) return `` // if it's emtpy just return
              var n = d.number.length > 1 ? number(d.number) : d.number;
              return d.number == `*` ? d.name : n;
            case `modal`:
              return `Contract ${Record.id(data.contract)} - ${data.contract.ref
                }`;
            case `minimum`:
              return d.minimum
                ? `Minimum Annual Fee: ${numeral(d.minimum).format(
                  `$0,0.[00]`
                )}`
                : ``;
            case `number`:
              return d[type].length > 1 && d[type] != this.ALL ? number(d[type]) : d[type];
            case `remove`:
              return this._delete ? `Confirm` : `Delete`;
            case `schedule`:
              return typeof d == `number`
                ? `Schedule ${String.fromCharCode(97 + d).toUpperCase()}`
                : d.hasOwnProperty('type') ? data.contract.schedules.find(s => s._id == d.scheduleId)?.name : d.name 
            case `signees`:
              return typeof d == `number`
                ? ordinal(d + 1)
                : `This contract has electronic signing disabled and requires no signatures!`;
            case `ticker`:
              return d.ticker.symbol
                ? `${d.ticker.symbol}` + d.ticker.name ?? ``
                : `-`;
            default:
              throw new Error(`Invalid text type, ${type}!`);
          }
        },
        tiers(s) {
          var format = {
            range: (v) => numeral(v).format(`$0,0`),
            rate: (v) => numeral(v).format(`0.[00]%`),
          };
          return s.breaks.map((b, i) => {
            var range;
            if (s.breaks.length > 1) {
              var start = i ? b + 1 : b;
              var end = i < s.breaks.length - 1 ? s.breaks[i + 1] : 0;
              range = end
                ? `${format.range(start)} - ${format.range(end)}`
                : `> ${format.range(start - 1)}`;
            } else {
              range = `> ${format.range(b)}`;
            }
            var rate = format.rate(s.rates[i]);
            return { range, rate };
          });
        },
        amend() {
          return Modal.open(`contract-amend`, {
            contract: { ...data.contract },
            accounts: this._accounts,
            methods: {
              success: data.methods.success,
            },
          });
        },
        terminate() {
          return Modal.open(`contract-terminate`, {
            contract: { ...data.contract },
            accounts: this._accounts,
            methods: {
              success: data.methods.success,
            },
          });
        },
      };
      this.contract = contract.init();
    },
  },
};
</script>
