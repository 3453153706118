import { numeral } from "../npm";
import mongoose from 'mongoose';

class List {
  /**
   * Checks if a value contains a search string. Handles both objects and primitive values.
   * 
   * @param {any} v - The value to be checked. This can be an object, array, or primitive value.
   * @param {string} s - The search string. This can be a simple keyword or multiple keywords separated by spaces.
   * @param {boolean} c - A boolean flag indicating case sensitivity. If `true`, the search is case-sensitive; otherwise, it is case-insensitive.
   * @returns {boolean} - Returns `true` if all search terms are found within the value; otherwise, returns `false`.
   * 
   * @example
   * const items = [
   *   { id: 1, name: "Contract A", details: { description: "This is contract A", tags: [{ name: "finance" }, { name: "legal" }] } },
   *   { id: 2, name: "Contract B", details: { description: "This is contract B", tags: [{ name: "finance" }, { name: "hr" }] } },
   *   { id: 3, name: "Contract C", details: { description: "This is contract C", tags: [{ name: "legal" }, { name: "hr" }] } }
   * ];
   * 
   * // Search for items with the keyword "finance"
   * const searchString = "finance";
   * const filteredItems = items.filter(item => List._contains(item, searchString, false));
   * console.log(filteredItems);
   * 
   * // Search for items with the keyword "finance" in the tags field
   * const searchString = "tags:finance";
   * const filteredItems = items.filter(item => List._contains(item, searchString, false));
   * console.log(filteredItems);
   * 
   * // Search for items with multiple keywords "finance legal"
   * const searchString = "finance legal";
   * const filteredItems = items.filter(item => List._contains(item, searchString, false));
   * console.log(filteredItems);
   */
  static _contains(v, s, c) {
    const terms = s.split(' ');
    return terms.every(term => this._containsSingleTerm(v, term, c));
  }

  static _containsSingleTerm(v, s, c) {
    var field, search;
    if (typeof v == `object` && v != null) {
      field = s.includes(`:`) ? s.split(`:`).shift() : ``;
      search = field ? s.split(`:`).pop() : s;
      for (var key of Object.keys(v)) {
        if (key == `_id`) continue; // Exclude _id field
        if (field && field != key) continue;
        if (Array.isArray(v[key])) {
          for (let item of v[key]) {
            if (this._containsSingleTerm(item, search, c)) return true;
          }
        } else if (this._containsSingleTerm(v[key], search, c)) return true;
      }
      return false;
    }

    if (mongoose.Types.ObjectId.isValid(v)) return false; // Skip any ObjectId

    return c ? String(v).includes(s) : String(v)
      .toLowerCase()
      .includes(s.toLowerCase());
  }

  static _search(s) {
    return s;
    var sea = s == `-` ? `\-` : s;
  }

  static filter(data, search = ``, caseSensitive = false) {
    return search
      ? data.filter((i) =>
        List._contains(i, List._search(search), caseSensitive)
      )
      : [...data];
  }

  static sort(data, key, asc = true) {
    return key
      ? data.sort((a, b) => {
        if (key == "date") {
          const [dateA, dateB] = [new Date(a[key]), new Date(b[key])];

          return asc
            ? dateA.getTime() - dateB.getTime()
            : dateB.getTime() - dateA.getTime();
        }

        return asc
          ? String(a[key]).localeCompare(String(b[key]))
          : String(b[key]).localeCompare(String(a[key]));
      })
      : [...data];
  }

  static records(...list) {
    var [a, b] = list;
    var r = Array.isArray(a) && Array.isArray(b)
      ? [a.length, b.length].map((v) => numeral(v).format(`0,0`))
      : ``;
    return r ? `Showing ${r[0]} of ${r[1]} record(s)` : ``;
  }
}

export default List;
