
<div>
    <p>Hello [client],</p>

    <p>Attached is your most recent statement of account fees paid to 603 Financial Advisors. These fees are debited
        directly from your Schwab account(s) and no action is required for you to remit payment.</p>

    <p>Fees are charged quarterly, in advance, during the months of January, April, July and October.</p>

    <p>Please email or call anytime with questions. Thank you very much,</p>

    <p>Chris Eddy, CFP®<br>
        603 Financial Advisors, LLC<br>
        P.O. Box 138<br>
        6 Main Street, #138<br>
        Newfields, NH 03856<br>
        603-956-4977<br>
        chris@603financial.com<br>
        <a href="http://www.603financial.com">www.603financial.com</a>
    </p>
    <p>
        <small>
            Advisory services are offered through 603 Financial Advisors, LLC.  Securities are offered by Fortune
            Financial Services, Inc., member FINRA/SIPC.  603 Financial Advisors, LLC and Fortune Financial
            Services, Inc. are separate entities. <br>
             Trading instructions sent via email will not be honored.  Please contact my office at (603) 770-3791 or
            Charles Schwab. at (800)515-2157 for all buy/sell orders.  Please be advised that communications
            regarding trades in your account are for information purposes only.  You should continue to rely on
            confirmations and statements received from the custodian(s) of your assets.  Any person who receives
            this communication in error is requested to immediately destroy the text of this communication without
            copying or further dissemination.  Your cooperation is appreciated. 
        </small>
    </p>
</div>
