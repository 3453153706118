<template>
  <div :key="table.master_key"
       class="h-100">
    <div class="search">
      <search :o="table.list"></search>
    </div>
    <div class="d-flex p-1 pb-2 header"
         style="height: 45px">
      <div class="d-flex actions my-auto mr-2">
        <div v-if="table.has_selected_item()"
             title="Create Invoice"
             class="btn btn-outline-secondary"
             v-on:click="table.click(`invoice-bulk`)">
          <i class="fas fa-file-invoice-dollar"></i>
          <span class="ml-1">Bulk Invoice</span>
        </div>
        <div v-if="table.has_selected_item()"
             :title="table.title(`discloseFeeScheduleBulk`)"
             class="btn btn-outline-secondary"
             v-on:click="table.click(`discloseFeeScheduleBulk`)">
          <i class="fas fa-mail-bulk"></i>
          <span class="ml-1">Disclose Fee Schedule</span>
        </div>
        <!-- disclose annual ammendment -->
        <div v-if="table.has_selected_item()"
             :title="table.title(`discloseAnnualAmendment`)"
             class="btn btn-outline-secondary"
             v-on:click="table.click(`discloseAnnualAmendment`)">
          <i class="fas fa-mail-bulk"></i>
          <span class="ml-1">Disclose Annual Amendment</span>
        </div>
      </div>
      <div v-if="!table.has_selected_item()"
           class="d-flex my-auto mr-2">
        <div>
          <span class="alt-action text-secondary">Select one or more contracts for options</span>
        </div>
      </div>
      <div class="flex-grow-1 my-auto text-right" 
      style="display: flex; flex-direction: column; font-size: 0.8rem" >
        <span v-text="table.records()"></span>
        <span v-text="table.selectedItemsCount()"></span>
      </div>
    </div>

    <template v-if="ready">
    <scroll-area class="pr-3">
      <a ref="download"
         class="d-none"></a>
      <table class="table table-sm">
        <thead>
          <tr>
            <th>
              <checkbox :o="table"
                        p="selected"
                        v-on:change="table.change()"></checkbox>
            </th>
            <th>
              <span>Id</span>
              <i class="fas fa-sort"
                 :class="table.class(`sort`, `id`)"
                 v-on:click="table.sort(`id`)"></i>
            </th>
            <th>
              <span>Ref</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `ref`)"
                 v-on:click="table.sort(`ref`)"></i>
            </th>
            <th>
              <span>Billing Type</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `billing`)"
                 v-on:click="table.sort(`billing`)"></i>
            </th>
            <th>
              <span>Accounts</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `accounts`)"
                 v-on:click="table.sort(`accounts`)"></i>
            </th>
            <th>
              <span>Schedules</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `schedules`)"
                 v-on:click="table.sort(`schedules`)"></i>
            </th>
            <th class="text-nowrap">
              <span>Date</span>
              <i class="fas fa-sort pl-1"
                 :class="table.class(`sort`, `date`)"
                 v-on:click="table.sort(`date`)"></i>
            </th>
            <!-- <th class="text-nowrap">
              <span>Status</span>
              <i
                class="fas fa-sort pl-1"
                :class="table.class(`sort`, `status`)"
                v-on:click="table.sort(`status`)"
              ></i>
            </th> -->
            <th class="text-nowrap"></th>
          </tr>
        </thead>
        <tbody :key="table.body_key">
          <tr v-for="(o, index) in table.items()"
              :key="o._id"
              data-cy="custodian-contract-row">
            <td>
              <checkbox :o="o"
                        p="select"
                        v-on:change="table.change(o)"
                        :disabled="o.status != `Active`"></checkbox>
            </td>
            <td>
              <a href="#" class="badge badge-custom"
                    :title="table.title(`contract`)"
                    v-on:click="table.click(`contract`, o)"
                    v-text="o.id"></a>
            </td>
            <td class="text-nowrap">
              <!-- <span v-text="o.ref"></span> -->
              <span>{{ o.ref.split("- ")[0] }}</span>
            </td>
            <td>
              <span style="text-transform: capitalize;">{{ o.billingType }}</span>
            </td>
            <td class="accounts">
              <div>
                <span v-for="(a, i) in o.accounts"
                      :key="i"
                      class="mr-2"
                      v-text="a"></span>
              </div>
            </td>
            <td class="schedules">
              <schedule-badges :schedules="o.schedules"></schedule-badges>
            </td>
            <td class="text-nowrap">
              <span v-text="o.date"></span>
            </td>
            <!-- <td>
              <span class="badge" :class="table.class(`status`, o)" v-text="o.status"></span>
            </td> -->
            <td class="text-nowrap">
              <i class="far fa-file-pdf mr-2"
                 :title="table.title(`pdf`)"
                 v-on:click="table.click(`pdf`, o)"></i>
              <i class="fas fa-dollar-sign mr-2"
                 :title="table.title(`bill`)"
                 :class="o.status != `Active` ? `disabled` : ``"
                 v-on:click="o.status == `Active` ? table.click(`bill`, o) : ``"></i>
              <i class="far fa-paper-plane mr-2"
                 :title="table.title(`discloseFeeSchedule`)"
                 v-on:click="table.click(`discloseFeeSchedule`, o)"></i>
              <i v-if="o.showSignatureReminder" class="far fa-bell mr-2"
                 :title="table.title(`reminder`)"
                 v-on:click="table.click(`reminder`, o)"></i>
            </td>
          </tr>
        </tbody>
      </table>
    </scroll-area>
  </template>
  <template v-else>
      <loading></loading>
  </template>
  </div>
</template>

<style scoped>
th {
  white-space: nowrap;
}

i.disabled {
  cursor: not-allowed !important;
  opacity: 0.1 !important;
}

span.alt-action {
  font-weight: 200;
  font-style: italic;
  font-size: 0.8rem;
}

div.search {
  margin-right: 1em;
  margin-top: -3em;
  float: right;
  width: 15em;
}

div.header div.actions,
div.header div.actions div {
  margin-right: 1em;
  cursor: pointer;
  font-size: 0.8rem;
}

table span {
  font-size: 0.9rem;
}

span.link {
  cursor: pointer;
  text-decoration: underline;
}

table th:nth-child(1) {
  width: 2em;
}

table th:nth-child(2) {
  width: 5em;
}

table th:nth-child(3) {
  width: 25%;
}

table th:nth-child(4) {
  width: auto;
}

table th:nth-child(5) {
  width: auto;
}

table th:nth-child(6) {
  width: 7em;
}

table th:nth-child(7) {
  width: 6em;
}

td.accounts div {
  display: flex;
  flex-direction: column;
}

td.accounts span,
td.schedules span {
  white-space: pre;
}

td {
  vertical-align: inherit;
}

i {
  opacity: 0.3;
}

i:hover,
i.sort,
i.active {
  cursor: pointer;
  opacity: 1;
}

span.badge {
  color: white;
  font-weight: normal;
  width: 5em;
}

span.schedule-badge {
  font-weight: normal;
  font-size: 0.8em;
  width: auto;
}

i.active {
  color: red;
}
</style>

<script>
import { scrollArea, search, checkbox, loading } from "../../../../component";
import ScheduleBadges from "../../../../component/schedule/schedule-badges.vue";

import { Api, Component, List, Modal, Record } from "../../../../factory";
import { numeral } from "../../../../npm";
import { alert, events, session } from "../../../../service";

export default {
  name: `FirmContracts`,
  get components() {
    return {
      scrollArea,
      search,
      checkbox,
      ScheduleBadges,
      loading
    };
  },
  data() {
    return {
      table: ``,
      master_key: 0,
      ready: false
    };
  },
  computed: {
    // a computed getter
    reversedMessage: function () {
      // `this` points to the vm instance
      return this.message.split("").reverse().join("");
    },
    items: function () {
      // `this` points to the vm instance
      return this.list;
    },
  },
  created() {
    this.init();
    const { table } = this;
    this.events = events.$watch({
      modal: table.events.bind(table),
    });
  },
  destroyed() {
    events.$unwatch(this.events);
  },
  methods: {
    init() {
      const state = session.get(`state`);
      const _this = this

      const table = {
        master_key: 0,
        selectedItems: {},
        disabled(type) {
          switch (type) {
            case `select`:
              return false;
            default:
              throw new Error(`Invalid disabled type, ${type}!`);
          }
        },
        _data: ``,
        _items: ``,
        async _load() {
          if (this._loading) return;
          this._loading = true;
          try {
            const url = `contracts/list?status=2&firmId=${state.firm._id}`
            const custodian = state.custodian._id != '*' ? `&custodianId=${state.custodian._id}` : ''
            const { data: contracts } = await Api.get(url + custodian);

            this._items = contracts
              .map((contract) => ({
                ...contract,
                select: this.select || this.selectedItems[contract._id],
              }))
              .sort((a, b) => b.date.localeCompare(a.date));
          } catch (e) {
            console.error(e);
          } finally {
            this._loading = false;
            _this.ready = true 
          }
        },
        _loading: ``,
        _pdf: async (contract) => {
          try {
            if (!contract.file) {
              if (!contract.pdf)
                return alert.warning(`Sorry, this contract has no pdf?`, 3e3);
              const { data: f } = await Api.get(
                `files?name=${encodeURIComponent(contract.pdf)}`
              );
              contract.file = f;
            }
            const el = this.$refs.download;
            el.setAttribute(`href`, contract.file.dataURI);
            el.setAttribute(`download`, contract.file.name);
            el.click();
            el.removeAttribute(`href`);
            el.removeAttribute(`download`);
          } catch (e) {
            console.error(e);
            alert.error(e.message);
          }
        },
        _record(o) {
          const accounts = o.accounts.map((a) => a.number);
          const schedules = o.schedules.map((o) => o.name);
          const { _id, date, ref } = o;
          const id = Record.id(o);
          const status = this._status[o.status].text;
          return {
            _id,
            accounts,
            date,
            id,
            ref,
            billingType: o.billing?.type,
            lineItems: o.lineItems,
            schedules,
            status,
            showSignatureReminder: o.signing_request_id != '' && o.status == 0,
            select: o.select ?? ``,
          };
        },
        _reload() {
          events.$emit(`nav`, `contracts`);
        },
        _status: [
          { badge: `warning`, text: `Pending` },
          { badge: `success`, text: `Signed` },
          { badge: `primary`, text: `Active` },
          { badge: `danger`, text: `Expired` },
          { badge: `warning`, text: `Amended` },
        ],
        class(type, d) {
          switch (type) {
            case `alerts`:
              return this._data.alerts.find((o) => o.parentId == d._id)
                ? `fas active`
                : `far`;
            case `sort`:
              return this.list.sort.key == d ? `sort` : ``;
            case `status`:
              return `badge-${this._status[this._status.findIndex((o) => o.text == d.status)]
                .badge
                }`;
            default:
              throw new Error(`Invalid class type, ${type}!`);
          }
        },
        async click(type, d) {
          switch (type) {
            case `account`:
              return Modal.open(`account`, {
                account: this._data.accounts.find((o) => o.number == d),
              });
            case `alerts`:
              return Modal.open(`contract-alerts`, {
                contract: this._items.find((o) => o._id == d._id),
              });
            case `bill`:
              return this.click(
                `invoice`,
                this._items.find((o) => o._id == d._id)
              );
            case `invoice`:
              return Modal.open(`invoice-create`, {
                contract: (await Api.get(`contracts?_id=${d._id}`)).data,
                methods: {
                  success: () => {
                    Modal.close();
                    events.$emit(`nav`, `invoices`);
                  },
                },
              });
            case `invoice-bulk`:

              const filteredList = this._items.filter((item) => item.select) 
              const hasAdvance = filteredList.some(c => c.billing.type.toLowerCase() == "advance")
              const hasArrears = filteredList.some(c => c.billing.type.toLowerCase() == "arrears")

              if (hasAdvance && hasArrears) 
              if (true) 
                return alert.warning(
                  `Unable to run bulk billing for contracts both in advance and in arrears. Rerun either separately.`
                  , 5e3);
              
              return Modal.open(`invoice-bulk-create`, {
                contracts: this._items.filter((item) => item.select),
                methods: {
                  success: () => {
                    Modal.close();
                    events.$emit(`nav`, `invoices`);
                  },
                },
              });
            case `contract`:
              return Modal.open(`contract`, {
                contract: (await Api.get(`contracts?_id=${d._id}`)).data,
                methods: {
                  success: () => {
                    Modal.close();
                    this._reload();
                  },
                },
              });
            case `create`:
              if (!this._items.find((o) => o.status == 2))
                return alert.warning(
                  this._items.length
                    ? `No active contract found! Click the contract $ icon to invoice`
                    : `No active contract found!`,
                  3e3
                );
              return this.click(
                `invoice`,
                this._items.find((o) => o.status == 2)
              );
            case `pdf`:
              return this._pdf(this._items.find((o) => o._id == d._id));
            case `discloseAnnualAmendment`: {

              const contracts = this._items
                .filter((item) => item.select)
                .map(({ _id }) => ({ _id }));

              // console.log("disclosing ammend for contracts", contracts)
              return this.discloseAnnualAmendment({
                contracts
              })

              // return this.discloseFeeScheduleModal({
              //   contracts,
              //   household:
              //     contracts.length == 1
              //       ? (
              //           await Api.get(
              //             `households/by-contract-id?_id=${contracts[0]._id}`
              //           )
              //         ).data
              //       : undefined,
              // });

            }
            case `discloseFeeScheduleBulk`: {
              const contracts = this._items
                .filter((item) => item.select)
                .map(({ _id }) => ({ _id }));

              return this.discloseFeeScheduleModal({
                contracts,
                household:
                  contracts.length == 1
                    ? (
                      await Api.get(
                        `households/by-contract-id?_id=${contracts[0]._id}`
                      )
                    ).data
                    : undefined,
              });
            }
            case `discloseFeeSchedule`:
              return this.discloseFeeScheduleModal({
                contracts: [d._id],
                household: (
                  await Api.get(`households/by-contract-id?_id=${d._id}`)
                ).data,
              });
            case `reminder`:
              return this.sendReminder(d);
          }
        },
        discloseFeeScheduleModal(data) {
          Modal.open("DiscloseFeeSchedule", {
            ...data,
            inEffect: true,
            methods: {
              send: async (data) => {
                try {
                  await Api.post(`email/disclose-fee-arrangement`, data);
                  alert.message("Fee arrangement sent!", 1500);
                } catch (error) {
                  console.error(error);
                  alert.error(error, 3000);
                }

                Modal.close();
              },
            },
          });
        },
        discloseAnnualAmendment(data) {
          // console.log("table disclose annual clicked! with data", data)
          Modal.open("DiscloseAnnualAmendment", {
            ...data,
            isBlackBarn: state.firm._id === '61f8869aaed0eed381295322',
            is603: state.firm._id === '64272b169c5b3d23455c40e5',
            isSims: state.firm._id === '65ba36319193e93b765bd754',
            methods: {
              send: async (data) => {
                try {
                  await Api.post(`email/disclose-annual-amendmentment`, data);
                  alert.message("Annual ammendment sent.", 1500);
                } catch (error) {
                  console.error(error);
                  alert.error(error, 3000);
                }

                Modal.close();
              },
            },
          });
        },
        sendReminder(d) {
          const c = this._items.find((o) => o._id == d._id)
          const data = { signatureRequestId: c.signing_request_id, signees: c.signees }
          try {
            Api.post(`/templates/remind`, data);
            alert.message("Signature reminder was sent.", 1500);
          } catch (error) {
            console.error(error);
            alert.error("An error occurred while sending the reminder to the client.", 3000);
          }
        },
        display(type, d) {
          switch (type) {
            case `value`:
              return numeral(d.value).format(`$0,0.00`);
            default:
              throw new Error(`Invalid display type, ${type}!`);
          }
        },
        events(e) {
          switch (e.name) {
            case `modal`:
              if (e.data && e.data.action && e.data.action == `hidden`) {
                this._load();
                // this.master_key += 1;
              }
              break;
          }
        },
        init(c) {
          this._data = { alerts: [], accounts: [] };
          this.state = Component.state(c, {
            invoice: {},
            list: {
              search: ``,
              sort: { asc: ``, key: `` },
            },
          });
          this.NONE = `-`;
          this._load();
          return this;
        },
        items() {
          const items = Array.from(this._items).map((o) => this._record(o));
          const { search, sort } = this.list;
          return List.sort(List.filter(items, search), sort.key, sort.asc);
        },
        get list() {
          return this.state.list;
        },
        NONE: ``,
        records() {
          return List.records(this.items(), this._items);
        },
        selectedItemsCount() {
          var a = this.items().filter((i) => i.select);
          var b = this._items;
          var r = Array.isArray(a) && Array.isArray(b)
            ? [a.length, b.length].map((v) => numeral(v).format(`0,0`))
            : ``;
          return r ? `Selected ${r[0]} of ${r[1]} record(s)` : ``;
        },
        sort(key) {
          const { sort } = this.list;
          sort.asc =
            sort.key == key
              ? typeof sort.asc == `boolean`
                ? !sort.asc
                : true
              : true;
          sort.key = key;
        },
        state: ``,
        title(type, d) {
          switch (type) {
            case `account`:
              return this._data.accounts
                .filter((o) => o.number == d)
                .slice(0, 1)
                .reduce((_, val) => val, "");
            case `alerts`:
              return `View notifications`;
            case `bill`:
              return `Invoice contract`;
            case `contract`:
              return `View ${type}`;
            case `pdf`:
              return `Download contract`;
            case `discloseFeeSchedule`:
              return `Mail to client`;
            case `discloseFeeScheduleBulk`:
              return `Send fee schedule to selected clients`;
            case `discloseAnnualAmendment`:
              return `Send Annual Amendment to the firm`;
            case `pdf`:
              return `Download contract`;
            case `instance`:
              return `View Smart Contract`;
            case `reminder`:
              return `Send signature reminder`;
            default:
              throw new Error(`Invalid title type, ${type}!`);
          }
        },
        has_selected_item() {
          return this.items() && this.items().some((el) => el.select);
        },
        selected: ``,
        body_key: 0,
        change(item) {
          if (!item) {
            if (this.selected) {
              const items = this.items();
              this.selectedItems = {};

              items
                .filter((i) => i.status == "Active")
                .forEach((el) => (this.selectedItems[el._id] = this.selected));

              this._items.forEach((o) => {
                o.select = !!this.selectedItems[o._id];
              });
            } else {
              this._items.forEach((o) => {
                o.select = this.selected;
              });
              this.selectedItems = {};
            }
            this.body_key += 1;
          } else {
            this.selectedItems[item._id] = item.select;
            this._items
              .filter((o) => item._id == o._id)
              .forEach((o) => (o.select = item.select));
          }
        },
      };
      this.table = table.init(this);
    },
  },
};
</script>
